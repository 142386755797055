import React, { useEffect } from 'react';

const DownloadDudu = () => {
  useEffect(() => {
    const link = document.createElement('a');
    link.href = '/static/Dudu_Brochure2023.pdf';
    link.download = 'Dudu Chinese Brochure.pdf';
    link.click();
  }, []);

  return (
    <div className="text-center margin-auto">
    <p><img src={require("~images/products/Dudu/dudu.png").default} alt="Dudu Chinese"/></p>
    <h4>You are downloading Dudu Chinese Brochure ...</h4>
  </div>
  );
};

export default DownloadDudu;